import React from 'react';
import { ListItem, ProductDetails, ProductImage, Button } from '../../styles/AdminPanelStyles';
interface Discount {
  quantity: string;
  price: string;
}

interface Product {
  id: number;
  name: {
    en: string;
    ru: string;
    geo: string;
  };
  price: number;
  image_url: string | null;
  unit: string;
  step?: number;
  discounts?: Discount[];
}

interface ProductListItemProps {
  product: Product;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
}

const ProductListItem: React.FC<ProductListItemProps> = React.memo(({ product, onDelete, onEdit }) => {
  const handleDelete = () => {
    const confirmed = window.confirm('Вы уверены, что хотите удалить этот товар?');
    if (confirmed) {
      onDelete(product.id);
    }
  };

  const unitLabel = product.unit === 'g' ? `${product.step} г` : product.unit;

  return (
    <ListItem>
      <ProductDetails>
        <ProductImage src={product.image_url || ''} alt={product.name.en} />
        <p>
          {product.name.en} - ${product.price} {unitLabel}
        </p>
        {product.discounts && product.discounts.length > 0 && (
          <div>
            <p>Скидки:</p>
            <ul>
              {product.discounts.map((discount, index) => (
                <li key={index}>
                  {`При покупке от ${discount.quantity} ${product.unit}, цена ${discount.price} за единицу`}
                </li>
              ))}
            </ul>
          </div>
        )}
        <Button onClick={handleDelete}>Удалить</Button>
        <Button onClick={() => onEdit(product.id)}>Редактировать</Button>
      </ProductDetails>
    </ListItem>
  );
});

export default ProductListItem;