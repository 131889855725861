import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { Cart } from '../prouctCart/ProductCartStyles';
import { theme } from '../../styles/Theme';
import ModalCard from './ModalCard';
import { useTranslation } from 'react-i18next';

interface CardPromoProps {
  title?: string;
  description?: string;
}

const CardPromo: React.FC<CardPromoProps> = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleCardClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const transition = {
    duration: 0.4,
    ease: [0.43, 0.13, 0.23, 0.96],
  };

  return (
    <LayoutGroup>
      <>
        <PromoCard
          onClick={handleCardClick}
          layoutId="promo-card"
          initial={{ borderRadius: 10 }}
          whileHover={{ scale: 1.0 }}
          whileTap={{ scale: 0.97 }}
          transition={transition}
        >
          <FlexWrapper direction="column">
            <Title>{title || t('promo.title')}</Title>
            <Description>
              {description || t('promo.description')}
            </Description>
          </FlexWrapper>
        </PromoCard>

        <AnimatePresence>
          {isOpen && (
            <ModalCard isOpen={isOpen} onClose={handleClose} layoutId="promo-card">
              <FlexWrapper direction="column">
                <Title>{title || t('promo.title')}</Title>
                <Description>
                  {description || t('promo.description')}
                </Description>
                <CloseButton onClick={handleClose}>&times;</CloseButton>
              </FlexWrapper>
            </ModalCard>
          )}
        </AnimatePresence>
      </>
    </LayoutGroup>
  );
};

// Styled components

const PromoCard = styled(motion(Cart))`
  max-height: 350px;
  overflow: hidden;
  box-sizing: border-box;
`;

const Overlay = styled(motion.div)`
  /* Ваши существующие стили */
`;

const AnimatedCard = styled(motion.div)`
  /* Ваши существующие стили */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;`;

const FlexWrapper = styled.div<{ direction?: string }>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
`;

const Title = styled.h5`
  margin-top: 10px;
  font-size: 24px;
  word-break: break-word;
`;

const Description = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

export default CardPromo;
