// UserContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface WebAppUser {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
}

interface UserContextProps {
  userData: WebAppUser | null;
  setUserData: (user: WebAppUser) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<WebAppUser | null>(null);

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
