import React, { useState } from 'react';
import { Section, SectionTitle, List } from '../../styles/AdminPanelStyles';
import { motion, AnimatePresence } from 'framer-motion';
import { Spinner } from '../../components/Spinner';
import ProductForm from './ProductForm';
import ProductListItem from './ProductListItem';
import useProducts from '../../components/hooks/useProducts';

interface Discount {
  quantity: string;
  price: string;
}
interface Product {
  id: number;
  name: {
    en: string;
    ru: string;
    geo: string;
  };
  price: number;
  image_url: string | null;
  unit: string;
  step?: number;
  discounts?: Discount[];
}

const ProductList: React.FC = () => {
  const { products, setProducts, loading, setLoading, globalError, setGlobalError } = useProducts();
  const [editProductId, setEditProductId] = useState<number | null>(null);

  const saveProduct = async (data: any, id?: number) => {
    setGlobalError(null);
    setLoading(true);
    try {
      const formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          if (key === 'image' && data[key].length > 0) {
            formData.append('image', data[key][0]);
          } else if (key === 'discounts') {
            formData.append('discounts', JSON.stringify(data[key]));
          } else {
            formData.append(key, data[key]);
          }
        }
      }

      const token = localStorage.getItem('token');
      const response = await fetch(id ? `/products/${id}` : '/products', {
        method: id ? 'PUT' : 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to save product');
      }

      const result = await response.json();
      const updatedProduct: Product = {
        id: result.id,
        name: {
          en: result.name_en,
          ru: result.name_ru,
          geo: result.name_geo,
        },
        price: result.price,
        image_url: result.image_url || null,
        unit: result.unit || 'kg',
        step: result.step || 1,
        discounts: result.discounts || [],
      };
      if (id) {
        setProducts(products.map((product) => (product.id === id ? updatedProduct : product)));
        setEditProductId(null);
      } else {
        setProducts([...products, updatedProduct]);
      }
    } catch (error) {
      console.error('Error saving product:', error);
      setGlobalError('Ошибка сохранения продукта.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddProduct = (data: any) => saveProduct(data);
  const handleSaveProduct = (data: any) => {
    if (editProductId !== null) {
      saveProduct(data, editProductId);
    }
  };
  const handleDeleteProduct = async (id: number) => {
    setGlobalError(null);
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/products/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete product');
      }

      setProducts(products.filter((product) => product.id !== id));
    } catch (error) {
      console.error('Error deleting product:', error);
      setGlobalError('Ошибка удаления продукта.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditProduct = (id: number) => setEditProductId(id);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Section>
      <SectionTitle>Список продуктов</SectionTitle>

      <ProductForm onSubmit={handleAddProduct} />

      {globalError && <p>{globalError}</p>}

      <List>
        <AnimatePresence>
          {products.map((product) =>
            editProductId === product.id ? (
              <motion.div
                key={product.id}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.3 }}
              >
                <ProductForm
                  onSubmit={handleSaveProduct}
                  initialData={{
                    nameEn: product.name.en,
                    nameRu: product.name.ru,
                    nameGeo: product.name.geo,
                    price: product.price.toString(),
                    unit: product.unit,
                    step: product.step?.toString(),
                    discounts: product.discounts,
                  }}
                  onCancel={() => setEditProductId(null)}
                />
              </motion.div>
            ) : (
              <motion.div
                key={product.id}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.3 }}
              >
                <ProductListItem product={product} onDelete={handleDeleteProduct} onEdit={handleEditProduct} />
              </motion.div>
            )
          )}
        </AnimatePresence>
      </List>
    </Section>
  );
};

export default ProductList;