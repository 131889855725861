import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Container } from '../../components/Container';
import { useCart } from './CartContext';
import { useAuth } from '../autoeization/AuthContext';
import { useTranslation } from 'react-i18next';
import { FlexWrapper } from '../../components/FlexWrapper';
import GooglePayButton from './GooglePayButton';
import DataSwitch from '../../components/dateSlider/dataSwith';
import { EditButton } from '../../styles/btns/secondBtns';
import {
  CartdiInner,
  CartItemWrapper,
  ItemDetails,
  DeleteButton,
  PurchaseButton,
  TotalPrice,
  ItemContext,
  ItemContextTitle,
  ErrorText,
  BascketTitle,
  DiscountInfo,
  PaymentMethodWrapper,
  RadioButtonLabel,
  RadioButtonInput,
} from './BasketStyles';

interface BasketProps {
  currentLanguage: string;
}

interface CartItem {
  id: number;
  title: string;
  quantity: number;
  price: number;
  unit: string;
  step?: number;
  discounts?: { quantity: number; price: number }[];
}

export const Basket: React.FC<BasketProps> = ({ currentLanguage }) => {
  const { t } = useTranslation();
  const { cartItems, removeItemFromCart, clearCart } = useCart();
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedDelivery, setSelectedDelivery] = useState<{
    day: string;
    time: string;
  } | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [dotCount, setDotCount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('online');

  const handleEditClick = useCallback(() => {
    setIsEditing((prev) => !prev);
  }, []);

  const calculateTotalPrice = useCallback(
    (
      price: number,
      quantity: number,
      unit: string,
      step: number = 1,
      discounts?: { quantity: number; price: number }[]
    ) => {
      let applicablePrice = price;

      if (discounts && discounts.length > 0) {
        const applicableDiscount = discounts
          .filter((discount) => quantity >= discount.quantity * step)
          .reduce(
            (prev, curr) => (curr.quantity > prev.quantity ? curr : prev),
            { quantity: 0, price }
          );

        applicablePrice = applicableDiscount.price;
      }

      return unit === 'g'
        ? applicablePrice * (quantity / step)
        : applicablePrice * quantity;
    },
    []
  );

  const totalPrice = useMemo(() => {
    return cartItems.reduce((sum, item) => {
      return (
        sum +
        calculateTotalPrice(
          item.price,
          item.quantity,
          item.unit,
          item.step || 1,
          item.discounts
        )
      );
    }, 0);
  }, [cartItems, calculateTotalPrice]);

  const deliveryCost = totalPrice > 0.01 ? 0 : 5;
  const totalWithDelivery = totalPrice + deliveryCost;

  useEffect(() => {
    if (user && error === t('cart.notAuthorized')) {
      setError(null);
    }
  }, [user, error, t]);

  const handlePurchase = useCallback(async () => {
    if (!user) {
      setError(t('cart.notAuthorized'));
      return;
    }

  // Проверяем, есть ли адрес и номер телефона
  if (!user.address || !user.phone) {
    setError(t('cart.missingInfo'));
    return;
  }

    setIsActive(true);

    const orderData = {
      userId: user.id,
      items: cartItems.map((item) => ({
        productId: item.id,
        description: item.title,
        quantity: Number(item.quantity),
        price: calculateTotalPrice(
          item.price,
          item.quantity,
          item.unit,
          item.step || 1,
          item.discounts
        ),
      })),
      total: totalWithDelivery,
      deliveryTime: selectedDelivery
        ? `${selectedDelivery.day}, ${selectedDelivery.time}`
        : null,
      deliveryAddress: user.address,
      paymentMethod: paymentMethod,
    };

    try {
      // Отправляем данные заказа на сервер
      const orderResponse = await fetch('https://enddel.com/orders', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderData),
      });

      if (!orderResponse.ok) {
        const errorText = await orderResponse.text();
        console.error('Ошибка при создании заказа:', errorText);
        throw new Error(t('cart.orderError'));
      }

      const order = await orderResponse.json();

      if (paymentMethod === 'online') {
        console.log('Платеж инициирован, URL оплаты:', order.paymentUrl);
        window.location.href = order.paymentUrl;
      } else {
        console.log('Заказ создан:', order);
        alert(t('cart.orderSuccess'));
        clearCart();
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Ошибка при обработке заказа или платежа:', error);
      setError(t('cart.orderError'));
      setIsActive(false);
    }
  }, [
    user,
    cartItems,
    totalWithDelivery,
    selectedDelivery,
    t,
    calculateTotalPrice,
    paymentMethod,
    clearCart,
  ]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isActive) {
      interval = setInterval(() => {
        setDotCount((prevDotCount) => (prevDotCount % 3) + 1);
      }, 500);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isActive]);

  const renderCartItem = useCallback(
    (item: CartItem) => {
      const itemTotalPrice = calculateTotalPrice(
        item.price,
        item.quantity,
        item.unit,
        item.step || 1,
        item.discounts
      );

      // Вычисляем количество, необходимое для следующей скидки
      let discountInfo = '';
      if (item.discounts && item.discounts.length > 0) {
        const step = item.step || 1;
        const totalQuantityInSteps = item.quantity / step;

        const sortedDiscounts = item.discounts.sort(
          (a, b) => a.quantity - b.quantity
        );
        const nextDiscount = sortedDiscounts.find(
          (discount) => totalQuantityInSteps < discount.quantity
        );

        if (nextDiscount) {
          const quantityNeeded =
            (nextDiscount.quantity - totalQuantityInSteps) * step;

          if (quantityNeeded <= step) {
            discountInfo = t('discountInfo', {
              quantityNeeded,
              unit: item.unit,
            });
          }
        }
      }

      return (
        <CartItemWrapper key={item.id}>
          <ItemDetails>
            <ItemContextTitle>{item.title}</ItemContextTitle>
            <ItemContext>
              {item.quantity} {item.unit}
            </ItemContext>
            <ItemContext>{itemTotalPrice} ₾</ItemContext>
            {discountInfo && <DiscountInfo>{discountInfo}</DiscountInfo>}
          </ItemDetails>
          {isEditing && (
            <DeleteButton
              isEditing={isEditing}
              onClick={() => removeItemFromCart(item.id)}
            >
              {t('cart.remove')}
            </DeleteButton>
          )}
        </CartItemWrapper>
      );
    },
    [isEditing, removeItemFromCart, t, calculateTotalPrice]
  );

  return (
    <Container width="100%">
      <CartdiInner>
        <FlexWrapper align="center" justify="space-between">
          <BascketTitle>{t('cart.title')}</BascketTitle>
          <EditButton onClick={handleEditClick}>
            {isEditing ? t('cart.finishEditing') : t('cart.edit')}
          </EditButton>
        </FlexWrapper>

        {cartItems.length === 0 ? (
          <p>{t('cart.empty')}</p>
        ) : (
          <>
            {cartItems.map(renderCartItem)}
            <CartItemWrapper>
              <ItemDetails>
                <strong>{t('cart.delivery')}: </strong>
                <span>
                  {deliveryCost === 0 ? t('cart.free') : `${deliveryCost} GEL`}
                </span>
              </ItemDetails>
            </CartItemWrapper>
            {user && (
              <CartItemWrapper>
                <ItemDetails>
                  <span>{t('cart.delivery_address')}:</span>
                  <span>{user.address || t('cart.no_address')}</span>
                </ItemDetails>
              </CartItemWrapper>
            )}
            <DataSwitch
              buttonText1={t('as_soon_as_possible')}
              buttonText2={t('schedule_delivery')}
              onSelectedDelivery={setSelectedDelivery}
            />
            <FlexWrapper justify="space-between" top="15px" bottom="15px">
              <TotalPrice>
                {t('cart.total')}: {totalWithDelivery} ₾
              </TotalPrice>
              <EditButton onClick={clearCart}>{t('cart.clear')}</EditButton>
            </FlexWrapper>

            {/* Выбор способа оплаты */}
            <PaymentMethodWrapper>
  <RadioButtonLabel checked={paymentMethod === 'online'}>
    <RadioButtonInput
      type="radio"
      name="paymentMethod"
      value="online"
      checked={paymentMethod === 'online'}
      onChange={() => setPaymentMethod('online')}
    />
    <span>{t('payment.onlinePayment')}</span>
  </RadioButtonLabel>

  <RadioButtonLabel checked={paymentMethod === 'cash'}>
    <RadioButtonInput
      type="radio"
      name="paymentMethod"
      value="cash"
      checked={paymentMethod === 'cash'}
      onChange={() => setPaymentMethod('cash')}
    />
    <span>{t('payment.cashOnDelivery')}</span>
  </RadioButtonLabel>

  <RadioButtonLabel checked={paymentMethod === 'cardOnDelivery'}>
    <RadioButtonInput
      type="radio"
      name="paymentMethod"
      value="cardOnDelivery"
      checked={paymentMethod === 'cardOnDelivery'}
      onChange={() => setPaymentMethod('cardOnDelivery')}
    />
    <span>{t('payment.cardOnDelivery')}</span>
  </RadioButtonLabel>
</PaymentMethodWrapper>

            <FlexWrapper direction="column" align="center">
            <PurchaseButton
  isActive={isActive}
  isDisabled={isActive}
  onClick={handlePurchase}
>
  {isActive
    ? paymentMethod === 'online'
      ? `${t('cart.redirecting')}${'.'.repeat(dotCount)}`
      : `${t('cart.processing')}${'.'.repeat(dotCount)}`
    : paymentMethod === 'online'
    ? t('cart.go_to_payment')
    : t('cart.order')}
</PurchaseButton>
              {paymentMethod === 'online' && (
                <GooglePayButton totalPrice={totalWithDelivery} />
              )}
            </FlexWrapper>
            {error && <ErrorText>{error}</ErrorText>}
          </>
        )}
      </CartdiInner>
    </Container>
  );
};

export default React.memo(Basket);
