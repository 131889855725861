import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { GlobalStyled } from './styles/globalStyled';
import { CartProvider } from './layout/basket/CartContext';
import { ThemeProvider } from 'styled-components'; // Импорт ThemeProvider
import { theme } from './styles/Theme'; // Импорт вашего объекта темы
import './i18n';
import './scripts/telegram-web-app.js';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AppWrapper = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
    }
  }, []);

  return (
    <>
      <GlobalStyled isOpen={isOpen} />
      <Router>
        <ThemeProvider theme={theme}> {/* Оберн ите в ThemeProvider */}
          <CartProvider>
            <App />
          </CartProvider>
        </ThemeProvider>
      </Router>
    </>
  );
};

root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);
