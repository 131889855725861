import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Form, Input, Button } from '../../styles/AdminPanelStyles';
interface Discount {
  quantity: string;
  price: string;
}
interface ProductFormData {
  nameEn: string;
  nameRu: string;
  nameGeo: string;
  price: string;
  unit: string;
  step?: string;
  image: FileList | null;
  discounts: Discount[];
}

interface ProductFormProps {
  onSubmit: (data: ProductFormData) => void;
  initialData?: Partial<ProductFormData>;
  onCancel?: () => void;
}

const ProductForm: React.FC<ProductFormProps> = ({ onSubmit, initialData = {}, onCancel }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<ProductFormData>({
    defaultValues: {
      nameEn: '',
      nameRu: '',
      nameGeo: '',
      price: '',
      unit: 'kg',
      step: '',
      discounts: [],
      ...initialData,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'discounts',
  });

  const onSubmitHandler = (data: ProductFormData) => {
    onSubmit(data);
    reset();
  };

  const unit = watch('unit');

  return (
    <Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Input
        type="text"
        {...register('nameEn', { required: 'Название продукта (английский) обязательно' })}
        placeholder="Название продукта (английский)"
      />
      {errors.nameEn && <p>{errors.nameEn.message}</p>}
      <Input
        type="text"
        {...register('nameRu', { required: 'Название продукта (русский) обязательно' })}
        placeholder="Название продукта (русский)"
      />
      {errors.nameRu && <p>{errors.nameRu.message}</p>}
      <Input
        type="text"
        {...register('nameGeo', { required: 'Название продукта (грузинский) обязательно' })}
        placeholder="Название продукта (грузинский)"
      />
      {errors.nameGeo && <p>{errors.nameGeo.message}</p>}
      <Input
        type="text"
        {...register('price', { required: 'Цена продукта обязательна' })}
        placeholder="Цена продукта"
      />
      {errors.price && <p>{errors.price.message}</p>}
      <Input type="file" {...register('image')} />
      {errors.image && <p>{errors.image.message}</p>}
      <label>
        Единица измерения:
        <select {...register('unit')}>
          <option value="kg">Килограммы</option>
          <option value="pcs">Штуки</option>
          <option value="g">Граммы</option>
        </select>
      </label>
      {unit === 'g' && (
        <label>
          Шаг (граммы):
          <select {...register('step')}>
            <option value="10">10 грамм</option>
            <option value="100">100 грамм</option>
            <option value="500">500 грамм</option>
          </select>
        </label>
      )}

      <div>
        <h3>Скидки</h3>
        {fields.map((field, index) => (
          <div key={field.id}>
            <Input
              type="number"
              {...register(`discounts.${index}.quantity` as const, { required: 'Количество обязательно' })}
              placeholder="Количество"
            />
            <Input
              type="number"
              {...register(`discounts.${index}.price` as const, { required: 'Цена за единицу обязательна' })}
              placeholder="Цена за единицу"
            />
            <Button type="button" onClick={() => remove(index)}>
              Удалить
            </Button>
          </div>
        ))}
        <Button type="button" onClick={() => append({ quantity: '', price: '' })}>
          Добавить скидку
        </Button>
      </div>

      <Button type="submit">Сохранить</Button>
      {onCancel && (
        <Button type="button" onClick={onCancel}>
          Отмена
        </Button>
      )}
    </Form>
  );
};

export default ProductForm;