import ButtonWithRipple from '../../styles/btns/ButtonStyles';
import styled, { keyframes } from 'styled-components';
import { theme } from '../../styles/Theme';

export const CartdiInner = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: ${theme.colors.mainBg};

  @media (max-width: 652px) {
    margin-bottom: 10px;
    padding-top: 20px;

  }
`;

export const CartItemWrapper = styled.div`
  display: flex;
  position: relative;
  margin-top: 15px;
  @media (max-width: 652px) {
    margin-bottom: 5px;
  }
`;

export const ItemDetails = styled.div`
  width: 100%;
`;

export const DeleteButton = styled.button<{ isEditing: boolean }>`
  position: absolute;
  right: ${({ isEditing }) => (isEditing ? '0' : '-5px')};
  opacity: ${({ isEditing }) => (isEditing ? 1 : 0)};
  transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #d9534f;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #c9302c;
  }
`;

export const PurchaseButton = styled(ButtonWithRipple)<{
  isActive: boolean;
  isDisabled: boolean;
}>`
  width: 103%;
  border-radius: 10px;
  padding: 10px 0px;
  background-color: ${({ isActive }) =>
    isActive ? theme.button.buttonActive : 'transparent'};
  color: ${({ isActive }) =>
    isActive ? 'white' : theme.button.buttonActive};
  border: ${({ isActive }) =>
    isActive
      ? `1px solid ${theme.button.buttonActive}`
      : '1px solid #0098EA'};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s, color 0.3s, border 0.3s;

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? theme.button.buttonActive : 'lightblue'};
  }

  &:disabled {
    background-color: grey;
    border-color: grey;
    cursor: not-allowed;
  }
`;

export const TotalPrice = styled.div`
  font-variation-settings: 'wght' 600;
  font-size: 20px;
`;

export const ItemContext = styled.span`
  margin-right: 25px;
  font-size: 16px;
`;

export const ItemContextTitle = styled.span`
  font-weight: bold;
  margin-right: 20px;
  font-size: 16px;
  text-align: center;
`;

export const ErrorText = styled.p`
  color: red;
  margin-top: 10px;
`;

export const BascketTitle = styled.h2`
  font-size: 24px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DiscountInfo = styled.div`
  color: ${theme.colors.font};
  font-size: 12px;
  margin-top: 2px;
  background-color: ${theme.colors.ShopWindowBg};
  margin-top: 5px;
  padding: 5px 5px;
  border-radius: 3px;
  width: 70%;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`;

export const ItemWrapper = styled.div`
  width: 100%;
  height: 20px;
`;

/* Payment Method Styles */
export const PaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  border-radius: 5px;
 background-color: ${theme.colors.ShopWindowBg};
 padding: 5px;
`;

 export const RadioButtonLabel = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: ${({ checked }) => (checked ? theme.colors.mainBg : 'transparent')};
  margin-bottom: 5px;
 

  &:hover {
     background-color: ${theme.colors.mainBg};
  }

  span {
    flex-grow: 1;
  }
`;

export const RadioButtonInput = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  /* border: 2px solid ${theme.colors.font}; */
  border: transparent;
  /* border:  1px solid black; */
  border-radius: 50%;
  margin-right: 14px;
  position: relative;
  transition: border 0.3s ease, background-color 0.3s ease;

  &:hover {
    /* border-color: ${theme.button.buttonDisabled}; */
    background-color: transparent;
  }

  &:checked {
    /* border-color: ${theme.button.buttonDisabled}; */
    background-color: transparent;
  }

  &:checked::after {
    content: '';
    width: 10px;
    height: 10px;
    /* background-color: ${theme.button.buttonDisabled}; */
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;