// src/components/PhoneEditor.tsx
import React, { useState } from 'react';
import axios from 'axios';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { EditButton } from '../../styles/btns/secondBtns';
import { useTranslation } from 'react-i18next';
import { User, useAuth } from '../autoeization/AuthContext';

interface PhoneEditorProps {
  user: User;
}

const PhoneEditor: React.FC<PhoneEditorProps> = ({ user }) => {
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [newPhone, setNewPhone] = useState<string | undefined>(
    user.phone ? `+995${user.phone}` : ''
  );
  const { t } = useTranslation();
  const { login } = useAuth();

  const handlePhoneSave = async () => {
    if (!isValidPhoneNumber(newPhone || '')) {
      alert(t('invalid_phone_number'));
      return;
    }

    if (user && newPhone) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Ошибка: токен отсутствует. Повторите авторизацию.');
          alert(t('authorization_required'));
          return;
        }

        const response = await axios.put(
          `https://enddel.com/api/users/${user.id}`,
          { phone: newPhone },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log('Ответ сервера при обновлении телефона:', response.data);
        // Предполагается, что сервер возвращает обновлённые данные пользователя напрямую
        login(response.data, token || '');
        setIsEditingPhone(false);

        // Обновление данных в Crisp
        if (window.$crisp) {
          window.$crisp.push(['set', 'user:phone', [newPhone]]);
        }

        alert(t('phone_update_success')); // Сообщение об успешном обновлении
      } catch (error: any) {
        if (error.response) {
          if (error.response.status === 409) {
            alert(t('phone_already_in_use'));
          } else if (error.response.status === 401) {
            alert(t('authorization_failed'));
          } else {
            console.error('Ошибка при обновлении телефона:', error.response.data);
            alert(t('phone_update_failed')); // Сообщение об ошибке обновления
          }
        } else {
          console.error('Ошибка при обновлении телефона:', error.message);
          alert(t('phone_update_failed'));
        }
      }
    }
  };

  return (
    <div>
      <p>
        {t('phone')}: {user.phone ? `${user.phone}` : t('no_phone')}
      </p>
      {!isEditingPhone && (
        <EditButton onClick={() => setIsEditingPhone(true)}>
          {t('edit_phone')}
        </EditButton>
      )}

      {isEditingPhone && (
        <div>
          <PhoneInput
            defaultCountry="GE"
            value={newPhone}
            onChange={setNewPhone}
            placeholder={t('enter_new_phone_placeholder')}
          />
          <button onClick={handlePhoneSave}>{t('save_phone')}</button>
          <button onClick={() => setIsEditingPhone(false)}>{t('cancel')}</button>
        </div>
      )}
    </div>
  );
};

export default PhoneEditor;
