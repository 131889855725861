// FlowTelegramAuth.tsx
import { useEffect } from 'react';
import { useUser } from './UserContext';

const FlowTelegramAuth: React.FC = () => {
  const { setUserData } = useUser();

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
      const user = window.Telegram.WebApp.initDataUnsafe.user;
      setUserData(user);

      fetch('/api/telegram-auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user }),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Server response:', data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [setUserData]);

  return null;
};

export default FlowTelegramAuth;
